import React, { useState } from 'react';
import emailjs, { init } from 'emailjs-com';

const SendProfileEmail = ({ profileData }) => {

    init("user_i2ilRxg8A2tZZmuuXuGiT");

    const [sendingStatus, setSendingStatus] = useState('null');

    let emailParams = {
        to_email: '',
        arbo_1: 'niet beantwoord',
        arbo_2: 'niet beantwoord',
        arbo_3: 'niet beantwoord',
        arbo_4: 'niet beantwoord',
        amf_1: 'niet beantwoord',
        amf_2: 'niet beantwoord',
        amf_3: 'niet beantwoord',
        mhc_1: 'niet beantwoord',
        mhc_2: 'niet beantwoord',
        mhc_3: 'niet beantwoord',
        opsporing_1: 'niet beantwoord',
        opsporing_2: 'niet beantwoord',
        opsporing_3: 'niet beantwoord',
        ev_1: 'niet beantwoord',
        misstanden_found: profileData.foundCollectables,
        misstanden_total: profileData.collectables.length,
    };

    const [toEmail, setToEmail] = useState('');
    const [lastSentDate, setLastSentDate] = useState(null);
    const allowSendingInterval = 5 * 60;

    const sendEmail = (e) => {
        e.preventDefault();

        setSendingStatus('null');

        if (process.env.REACT_APP_CAN_SEND_EMAIL === 'false') {
            setSendingStatus("Het is in deze demo modus helaas niet mogelijk om je profiel te versturen.")
            return;
        }

        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(toEmail) === false) {
            console.log("Invalid email!")
            return;
        }

        let timeBetweenSent = 6 * 60;

        // only account for interval when there has been at least an email sent already
        if (lastSentDate !== null) {
            timeBetweenSent = (new Date() - lastSentDate.getTime()) / 1000;
        }

        // if interval time has not passed
        if (timeBetweenSent < allowSendingInterval) {

            const min = Math.floor((allowSendingInterval - timeBetweenSent) / 60);
            const sec = Math.round((allowSendingInterval - timeBetweenSent) - min * 60);

            setSendingStatus(`Probeer het over ${min}:${sec.toString().padStart(2, "0")} nogmaals`);
            alert(`Je hebt jezelf je profiel al gestuurd.\n\nOver ${min}:${sec.toString().padStart(2, "0")} kun je het nogmaals versturen.`);
            return;
        }

        setEmailParams();

        setSendingStatus("E-mail wordt verstuurd...");

        emailjs.send('service_d8c5h53', 'template_6z96bkd', emailParams, 'user_i2ilRxg8A2tZZmuuXuGiT')
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                setLastSentDate(new Date());
                setSendingStatus('E-mail succesvol verstuurd!');

            }, function (error) {
                console.log('FAILED...', error);
                setSendingStatus('E-mail niet verstuurd: ' + error.status);
            })
    }

    const setEmailParams = () => {

        emailParams.to_email = toEmail;

        const kennisvragen = profileData.kennisvragen;

        emailParams.arbo_1 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag1'));
        emailParams.arbo_2 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag2'));
        emailParams.arbo_3 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag3'));
        emailParams.arbo_4 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag4'));

        emailParams.amf_1 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag5'));
        emailParams.amf_2 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag6'));
        emailParams.amf_3 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag7'));

        emailParams.mhc_1 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag8'));
        emailParams.mhc_2 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag9'));
        emailParams.mhc_3 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag10'));

        emailParams.opsporing_1 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag11'));
        emailParams.opsporing_2 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag12'));
        emailParams.opsporing_3 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag13'));

        emailParams.ev_1 = getVraagStatus(kennisvragen.find(i => i.Variable === 'vraag14'));
    }

    const getVraagStatus = (i) => {
        if (i.Value === '0')
            return 'niet beantwoord';
        else if (i.Value === '1')
            return 'goed';
        else if (i.Value === '2')
            return 'fout';
    }

    const emailInputChange = (input) => {

        setToEmail(input.target.value);
    }

    return (
        <div className="w-full px-2 py-2 mb-2 bg-persona-greenLight rounded-lg shadow-lg" >
            <p className="text-center text-lg mb-1 text-persona-white font-header">Profiel versturen naar je e-mailadres?</p>
            <form onSubmit={sendEmail} className="w-full flex flex-row mt-auto rounded-lg">
                <input className="flex-1 w-0 px-3 text-black text-opacity-70 py-2 rounded-l-lg text-md" required type="email" name="to_email" onChange={emailInputChange} placeholder="e-mailadres.." />
                <input className="flex-0 pl-4 pr-4 rounded-r-lg bg-persona text-persona-white tracking-wide" type="submit" value="Send" />
            </form>
            {
                sendingStatus === 'null' ?
                    null :
                    <div className="text-center mt-2 text-persona-white">{sendingStatus}</div>
            }
        </div>
    );
}

export default SendProfileEmail
