import { init } from "emailjs-com";
import { useState, useEffect } from "react";

function getSavedValue(key, initialValue) {

    let savedValue = null;
    try {
        savedValue = JSON.parse(localStorage.getItem(key));
    } catch (err) {
        savedValue = null;
    }

    if (savedValue !== null)
        return savedValue;

    if (initialValue instanceof Function)
        return initialValue();

    return initialValue;
}

export default function useLocalStorage(key, initialValue, allowNull) {
    const [value, setValue] = useState(() => {
        return getSavedValue(key, initialValue);
    });

    useEffect(() => {
        if (initialValue === null && allowNull === false)
            return;

        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
}