import React, { useEffect, useRef, useState } from 'react';
import useLocalStorage from '../customHooks/useLocalStorage';
import {
    getEntityWithSpeakerId,
    getSelectedSceneFromId,
    isEntityPlayer,
    getDialogHistory,
    myConsoleLog,
    executeNewPlayerAction,
} from '../jsonData/DataWrangler';
import MeldMisstandModel from './MeldMisstandModal';
import WhatsappHeader from './whatsappComponents/WhatsappHeader';
import WhatsappMessage from './whatsappComponents/WhatsappMessage';
import WhatsappOptions from './whatsappComponents/WhatsappOptions';

export const Scene = ({ selectedId, onChangeScene, addMissionUpdate }) => {

    const [previousSceneLength, setPreviousSceneLength] = useState(0);
    const [storedId, setStoredId] = useState(useLocalStorage('selectedId', selectedId, false));

    const [currentRootNode, setCurrentRootNode] = useState(getSelectedSceneFromId(selectedId ? selectedId : storedId[0]));
    const [currentScene, setCurrentScene] = useState([]);

    const [collectablesSelectorVis, setCollectablesSelectorVis] = useState(true);

    // scrolls to the bottom whenever the size of the message array changes
    const messageEndRef = useRef(null);
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });

        setCurrentScene(getDialogHistory());
        setCurrentRootNode(getSelectedSceneFromId(selectedId ? selectedId : storedId[0]));

        // checks the newly added entries for mission updates.
        // if found sends them down the line to the mission update window
        const newAddedAmount = (currentScene.length - previousSceneLength);

        const newlyAdded = currentScene.slice(currentScene.length - newAddedAmount);

        const missionUpdates = newlyAdded.filter(entry => {
            var entity = getEntityWithSpeakerId(entry.Properties.Speaker);
            return entity.Properties.DisplayName === 'Mission update';
        });

        for (let mu in missionUpdates) {
            addMissionUpdate(missionUpdates[mu]);
        }

    }, [currentScene.length]);

    const onClickedOption = (id) => {

        setPreviousSceneLength(currentScene.length);

        if (process.env.REACT_APP_DEBUG_MODE === 'true')
            console.log(`Clicking with Id: ${id}`);
        const newScene = executeNewPlayerAction(getSelectedSceneFromId(id), true);

        onChangeScene(newScene);
    }

    let delay = 0;

    const openCollectablesModel = () => {
        setCollectablesSelectorVis(!collectablesSelectorVis);
    }

    return (
        <div className="h-screen mx-auto flex flex-col relative bg-gradient-to-b from-persona-greenLight to-persona-greenDark overflow-hidden">

            <WhatsappHeader currentNode={currentRootNode} />

            <div className="overflow-y-auto no-scrollbar flex-col flex-1 p-5 bg-persona-white rounded-2xl shadow-inner">

                {currentScene.map((scene, key) => {

                    if (scene.Type !== 'DialogueFragment')
                        return null;

                    const isPlayer = isEntityPlayer(scene.Properties.Speaker);
                    const entity = getEntityWithSpeakerId(scene.Properties.Speaker);

                    if (isPlayer === false)
                        delay += 800;
                    else
                        delay = 0;

                    if ((entity === null) || (entity === undefined)) {
                        myConsoleLog("ERROR: null or undefined entity");
                    }

                    return (
                        <WhatsappMessage key={key} isPlayer={isPlayer} scene={scene} entity={entity} delay={delay} />
                    )
                })}

                <div ref={messageEndRef} />
            </div>

            {
                collectablesSelectorVis === true ? null : <MeldMisstandModel selectedId={selectedId ? selectedId : storedId[0]} />
            }

            <WhatsappOptions currentNode={currentRootNode} onClickedOption={onClickedOption} delay={delay} openCollectablesModal={openCollectablesModel} />
        </div >
    )
}

export default Scene



