import React from 'react';
import { useHistory } from "react-router-dom";

import UitlegTekst from './UitlegTekst';
import { RiArrowGoBackLine } from 'react-icons/ri';

import { getGlobalVariables } from '../jsonData/DataWrangler';

const Guide = () => {

    let history = useHistory();

    const displayVars = () => {

        return (
            JSON.stringify(getGlobalVariables(), null, 0)
        )
    }

    return (
        <div className="px-5 pb-8 pt-4 flex flex-col h-screen relative">
            <div className="flex items-center w-full h-24 text-xl">
                <p className="w-full text-3xl text-center font-header text-persona-white tracking-wide">
                    Speluitleg
                </p>
            </div>
            <div className="h-full overflow-y-auto mb-5 px-5 py-5 bg-persona-white rounded-2xl shadow-md text-sm">
                <UitlegTekst />
            </div>

            <div className="bg-persona-greenLight rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer" onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

export default Guide
