import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import metadata from './metadata.json';

const history = createBrowserHistory();

ReactDOM.render(

  <React.StrictMode>
    <BrowserRouter basename={`/${metadata.baseName}`} history={history}>
      <App />
    </BrowserRouter>
  </React.StrictMode >,
  document.getElementById('root')
);
