let keysToRemove = [
    "Color",
    "Size",
    "ZIndex",
    "Position",
    "MenuText",
    "StageDirections",
    "ShortId",
    "TechnicalName",
    "Label"
];

module.exports.cleanJson = (jsonData, optionalCustomKeys = null) => {

    if (optionalCustomKeys !== null && optionalCustomKeys !== undefined) {
        keysToRemove = optionalCustomKeys;
    }

    return cleanJsonData(jsonData);
}

function cleanJsonData(jsonData) {
    return JSON.parse(JSON.stringify(jsonData, replacer));
}

function replacer(key, value) {
    if (keysToRemove.includes(key)) {
        return undefined;
    }
    return value;
}