import React from 'react'

const uitlegTekst = () => {

    const headerStyle = 'text-persona text-2xl pb-2 font-header';

    return (
        <div className="text-base leading-relaxed">

            Deze Persona versie is gemaakt om met 3 mensen of in groepjes te spelen, waarin jullie een inspectie missie uit gaan voeren.<br /><br />
            Op de poster staan verschillende activiteiten rond een bouwplaats elk aangegeven met een QR code.<br /><br />
            Door deze te scannen kan je verschillende personages spreken en rond het thema opdrachten uitvoeren.<br /><br />
            In het begin moeten de spelers een rol kiezen, zorg ervoor dat in je groepje er tenminste een van iedere rol wordt gekozen.<br /><br />
            Er zijn drie acts en tussen de acts bespreken de spelers de gemaakte keuzes en aangetroffen situaties met elkaar.<br /><br />
            Dan wordt er een aanpak besproken voor de volgende act enz.<br /><br />
            Succes!<br /><br />
            Begin met de <b>[ Kies een rol ]</b> knop of door een QR code te scannen.

        </div>
    )
}

export default uitlegTekst