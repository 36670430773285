import React, { useEffect, useState, useRef } from 'react';
import { determineNewActionArray, getSelectedSceneFromId, executeNewPlayerAction, getVariableWithName } from '../../jsonData/DataWrangler';

import { BiChevronsRight } from 'react-icons/bi';

const WhatsappOptions = ({ currentNode, delay, openCollectablesModal, onClickedOption }) => {

    const [buttonsActive, setButtonsState] = useState(true);
    const [collectablesModalState, setCollectablesModelState] = useState(false);

    const [collectablesName, setCollectablesName] = useState(getVariableWithName("GameSettings", "collectablesName")?.Value);

    let btnRef = useRef();
    let anArray = determineNewActionArray(currentNode);

    useEffect(() => {
        if (delay === 0)
            return;

        setButtonsState(false);
        setTimeout(() => {
            setButtonsState(true);
        }, delay + 500);
    }, [delay]);

    function onClickOption(id) {

        if (buttonsActive === false)
            return;

        onClickedOption(id);
    }

    const toggleCollectablesModal = () => {
        openCollectablesModal();

        setCollectablesModelState(!collectablesModalState);
    }

    return (
        <div className="w-full mt-2">
            <div className="rounded-2xl space-y-3 w-full px-4 mb-5">
                <div className="flex flex-row space-x-2">
                    <div className="flex flex-row text-persona-white relative flex-1">
                        <BiChevronsRight className="h-12 w-16 text-persona-greenLight flex-none" />
                        <div className="text-lg flex-grow font-bold pt-2">Reageer</div>

                        <button className={`text-persona-white border-2 border-persona-white hover:bg-persona-greenLight ${collectablesModalState === true ? 'bg-persona-greenLight' : 'bg-none'} text-sm rounded-lg text-center p-1 px-2 my-1`}
                            onClick={() => toggleCollectablesModal()}>
                            {collectablesModalState === false ? `Open ${collectablesName} melder` : `Sluit ${collectablesName} melder`}
                        </button>
                    </div>
                </div>

                {anArray.map((option, key) => {
                    return (
                        <button ref={btnRef} onClick={() => onClickOption(option.Properties.Id)} key={key}
                            className={`rounded-2xl rounded-tr-none px-5 py-4 shadow-lg outline-none focus:outline-none transition duration-100
                                transform hover:scale-99 text-md text-persona-white tracking-wider bg-persona-unchecked hover:bg-persona-greenLight
                                w-full ${buttonsActive === false ? '' : ''}`}>
                            {option.Properties.Text}
                        </button>
                    )
                })}
            </div>
        </div>
    );
}

export default WhatsappOptions;