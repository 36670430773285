import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './App.css';
import Main from './components/Main';
import MissionUpdate from './components/MissionUpdate';
import { loadDataFromJson } from './jsonData/DataWrangler';

import BackgroundDecorations from './components/BackgroundDecorations';
const fs = require('fs');

const App = () => {

  const [data, setLoadedData] = useState();

  // very important! Loads the data from either the localstorage or the original file.
  useEffect(() => {
    //const d = loadDataFromJson()
    //setLoadedData(d);
    
    FetchData();
    
  }, [])
  
  const FetchData = async () => {
      fetch('data/data.json'
          , {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
      )
      .then((response) => {
        if (response.ok) {
          console.log("SUCCES: Loaded data from /data/data.json");
          return response.json();
        }
        else {
          console.log("WARNING: Could not load data from /data/data.json");
          return null;
        }
      })
      .then((jsonResponse) => {

        setLoadedData(loadDataFromJson(jsonResponse));
      })
      .catch((error) => { console.log("ERROR: " + error) })
  }

  let history = useHistory();

  const [currentScene, setCurrentScene] = useState(null);

  const changeScene = (newScene) => {

    if (newScene.sceneType === '/') {
      //console.log('Back to scan changeScene:', newScene.sceneId);
      history.replace('/');
    }
    else if (newScene.sceneType === 'scan') {
      //console.log('Back to scan changeScene:', newScene.sceneId);
      history.replace('/scan');
    }
    else if (newScene.sceneType === 'scene') {
      // only replace history if different than current

      if (currentScene === null || currentScene.sceneType !== newScene.sceneType)
        history.replace('/scene');
    }

    setCurrentScene(newScene);
  }

  const resetCurrentScene = () => {
    setCurrentScene(null);
  }

  const [pendingMissionUpdates, updatePendingMissionUpdates] = useState([]);


  const addMissionUpdate = (missionUpdate) => {

    if (pendingMissionUpdates.some(update => update.Properties.Id === missionUpdate.Properties.Id)) {
      //console.log("Exists already!");
      return;
    }

    updatePendingMissionUpdates(previousArr => [...previousArr, missionUpdate]);
  }

  const dismissMissionUpdate = (missionUpdate) => {

    //console.log("Dismiss", missionUpdate)

    const index = pendingMissionUpdates.indexOf(missionUpdate);
    if (index > -1) {
      updatePendingMissionUpdates(pendingMissionUpdates.filter((entry) => entry !== missionUpdate));
    }
  }

  if (!data) {
    return (
      <div className="max-w-screen-sm m-auto">
        <BackgroundDecorations />
        <p className="absolute font-header text-3xl h-full w-full text-center text-persona-white">Loading...</p>
      </div>
    )
  }
  else {
    return (
      <React.Fragment >
        <Main onChangeScene={changeScene} resetCurrentScene={resetCurrentScene} addMissionUpdate={addMissionUpdate} />

        <MissionUpdate pendingMissionUpdates={pendingMissionUpdates} dismissMissionUpdate={dismissMissionUpdate} />
      </React.Fragment >
    )
  }
}

export default App;
